import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { departaments as fetchDepartments, userRegister, verificarCI, verificarRUT, userRegisterSimply } from '../../services/UserServices';
import Loading from '../../components/loading/Loading';
import { userSetStorageAction } from '../../redux/actions/UserActions';
import { connect } from 'react-redux';
import { Constants } from '../../Constants';
import FacebookLogin from 'react-facebook-login';
import { userLoginFB } from '../../services/UserServices';
import TitleBox from '../../ui-components/title-box';
import './Register.scss';
import { Facebook, Google, Instagram } from 'react-bootstrap-icons';

let title = 'Registrar';

const Register = (props) => {

    const { setLocalStorage, configAccount } = props;

    const [departaments, setDepartaments] = useState([])
    const [form, setForm] = useState({
        name: '',
        mobile: '',
        document: '',
        address: '',
        email: '',
        password: '',
        confirmPassword: '',
        type_document: Constants.TYPE_DOCUMENT_CI,
        city: '',
        departamentSelect: 10
    })
    const [messageError, setMessageError] = useState('')
    const [loading, setLoading] = useState(false)

    const [isPasswordShown1, setIsPasswordShown1] = useState(false);
    const [isPasswordShown2, setIsPasswordShown2] = useState(false);

    const isFacebookApp = () => {
        const ua = navigator.userAgent || navigator.vendor || window.opera;
        return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1 || ua.indexOf('Instagram') > -1;
    };

    useEffect(() => {
        if (isFacebookApp()) {
            (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) { return; }
                js = d.createElement(s); js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        }

        if (configAccount?.config?.title_tabs) {
            document.title = configAccount.config.title_tabs + ' - ' + title;
        }
    }, [configAccount])

    const handleChange = (event, maxLength = null) => {
        const { name, value } = event.target;
        setForm({ ...form, [name]: maxLength ? value.slice(0, getMaxLength()) : value });
    }

    useEffect(() => {
        const fetchDepartamens = async () => {
            try {
                let response = await fetchDepartments();
                setDepartaments(response.data.data.departaments);
            } catch (error) {
                console.log(error)
            }
        }
        fetchDepartamens();
    }, [])

    const register = async (event) => {
        event.preventDefault();
        setLoading(true);
        setMessageError('');
        if (form.name.match(/(\w+)/g).length < 2) {
            setMessageError('El nombre debe tener al menos 2 palabras');
            setLoading(false);
            return;
        }
        if (form.password !== form.confirmPassword) {
            setMessageError('Las contraseñas no coinciden');
            setLoading(false);
            return;
        }
        if (form.password.length < 6) {
            setMessageError('La contraseña debe contener al menos 6 caracteres');
            setLoading(false);
            return;
        }
        if (form.document !== '' && form.type_document === Constants.TYPE_DOCUMENT_CI) {
            let isValid = await verificarCI(form.document);
            if (!isValid) {
                setMessageError('La CI ingresada no tiene un formato válido');
                setLoading(false);
                return;
            }
        }
        if (form.document !== '' && form.type_document === Constants.TYPE_DOCUMENT_RUT) {
            let isValid = await verificarRUT(form.document);
            if (!isValid) {
                setMessageError('El RUT ingresado no tiene un formato válido');
                setLoading(false);
                return;
            }
        }
        try {
            let response = await userRegister({
                email: form.email,
                password: form.password,
                departamentId: form.departamentSelect,
                name: form.name,
                phone: form.mobile,
                document: form.document,
                direction: form.address,
                type_document: form.type_document,
                city: form.city
            });
            setLoading(false);
            setLocalStorage(response.data.data);
        } catch (error) {
            setMessageError(error.response.data.message);
            setLoading(false);
        }
    }

    const simplyRegister = async (event) => {
        event.preventDefault();
        setLoading(true);
        setMessageError('');

        if (form.name.length < 2) {
            setMessageError('El nombre debe tener al menos 2 palabras');
            setLoading(false);
            return;
        }
        if (form.pin.length < 4) {
            setMessageError('El pin debe tener al menos 4 caracteres');
            setLoading(false);
            return;
        }

        try {
            let response = await userRegisterSimply({
                name: form.name,
                phone: form.mobile,
                pin: form.pin,
            });
            setLoading(false);
            setLocalStorage(response.data.data);
        } catch (error) {
            setMessageError(error.response.data.message);
            setLoading(false);
        }
    }

    const getMaxLength = () => {
        if (form.type_document === Constants.TYPE_DOCUMENT_CI) {
            return 8;
        }
        if (form.type_document === Constants.TYPE_DOCUMENT_RUT) {
            return 12;
        }
        return 50;
    }

    const loginFB = async (data) => {
        setLoading(true);
        try {
            let response = await userLoginFB({
                email: data.email,
                fbId: data.id,
                name: data.name,
                json: data
            });
            setLoading(false);
            setLocalStorage(response.data.data);
        } catch (error) {
            setLoading(false);
            setMessageError(error.response.data.message);
        }
    }

    const responseFacebook = (response) => {
        loginFB(response);
    }

    if (!configAccount.config) {
        return null;
    }

    const togglePasswordVisibility1 = () => {
        setIsPasswordShown1(isPasswordShown1 => !isPasswordShown1);
    };

    const togglePasswordVisibility2 = () => {
        setIsPasswordShown2(isPasswordShown2 => !isPasswordShown2);
    };

    return (
        <React.Fragment>
            {loading && <Loading />}
            <section className="register-background mt-5">
                <div className="container register-area pt-5">
                    <div className="signup-content">
                        <div className="col-12 mb-5">
                            <div className="login-content">
                                <div className="text-center d-flex flex-column">
                                    <h1 className='font-weight-bold text-primary-background'>
                                        Crear cuenta
                                    </h1>
                                </div>
                            </div>
                        </div>
                        {
                            !configAccount.config?.simply_auth ?
                                <form className="signup-form row" onSubmit={register}>
                                    <div className="form-group col-12 col-md-6">
                                        <input type="text" className="form-control  rounded-pill" style={{ fontFamily: "FontAwesome", fontSize: '18px' }} placeholder="&#xf007;&nbsp;&nbsp;Nombre completo (*)" name="name"
                                            value={form.name} onChange={handleChange} required />
                                    </div>

                                    <div className="form-group col-12 col-md-6">
                                        <input type="email" className="form-control rounded-pill" style={{ fontFamily: "FontAwesome", fontSize: '18px' }} placeholder="&#xf0e0;&nbsp;&nbsp;Email (*)" name="email"
                                            value={form.email} onChange={handleChange} required />
                                    </div>

                                    <div className="form-group col-12 col-md-6">
                                        <div style={{ display: 'flex' }}>
                                            <select className="form-control rounded-pill" name="type_document" onChange={handleChange} style={{ width: 'max-content', fontFamily: "FontAwesome" }}>
                                                <option value={Constants.TYPE_DOCUMENT_CI}
                                                    selected={form.type_document === Constants.TYPE_DOCUMENT_CI ? true : false}>CI</option>
                                                <option value={Constants.TYPE_DOCUMENT_RUT}
                                                    selected={form.type_document === Constants.TYPE_DOCUMENT_RUT ? true : false}>RUT</option>
                                                <option value={Constants.TYPE_DOCUMENT_OTHER}
                                                    selected={form.type_document === Constants.TYPE_DOCUMENT_OTHER ? true : false}>Otro</option>
                                            </select>
                                            <input type="text" className="form-control rounded-pill" style={{ fontFamily: "FontAwesome", fontSize: '18px' }} placeholder="&#xf2bb;&nbsp;&nbsp;Documento" name="document"
                                                value={form.document} onChange={(e) => handleChange(e, true)} />
                                        </div>
                                    </div>

                                    <div className="form-group col-12 col-md-6">
                                        <input type="text" className="form-control rounded-pill" style={{ fontFamily: "FontAwesome", fontSize: '18px' }} placeholder="&#xf095;&nbsp;&nbsp;Celular (*)" name="mobile"
                                            value={form.mobile} onChange={handleChange} required />
                                    </div>

                                    <div className="form-group col-12 col-md-6">
                                        <input type="text" className="form-control rounded-pill" style={{ fontFamily: "FontAwesome", fontSize: '18px' }} placeholder="&#xf041;&nbsp;&nbsp;Dirección" name="address"
                                            value={form.address} onChange={handleChange} />
                                    </div>

                                    <div className="form-group col-12 col-md-3">
                                        <input type="text" className="form-control rounded-pill" style={{ fontFamily: "FontAwesome", fontSize: '18px' }} placeholder="&#xf041;&nbsp;&nbsp;Ciudad" name="city"
                                            value={form.city} onChange={handleChange} />
                                    </div>

                                    <div className="form-group col-12 col-md-3">
                                        <div className="select-box">
                                            <select className="form-control rounded-pill" style={{ fontFamily: "FontAwesome", fontSize: '18px' }} name="departamentSelect" onChange={handleChange}>
                                                {
                                                    departaments?.map(item =>
                                                        <option key={item.id} value={item.id} selected={item.id === 10 ? true : false}>{item.name}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group col-12 col-md-6" style={{ position: 'relative' }}>
                                        <input type={isPasswordShown1 ? "text" : "password"} className="form-control rounded-pill" style={{ fontFamily: "FontAwesome", fontSize: '18px' }} placeholder="&#xf084;&nbsp;&nbsp;Contraseña (*)" name="password"
                                            value={form.password} onChange={handleChange} required min="6" />
                                        <button type='button' onClick={togglePasswordVisibility1} className="toggle-password">
                                            {isPasswordShown1 ? 'Ocultar' : 'Mostrar'}
                                        </button>
                                    </div>

                                    <div className="form-group col-12 col-md-6" style={{ position: 'relative' }}>
                                        <input type={isPasswordShown2 ? "text" : "password"} className="form-control rounded-pill" style={{ fontFamily: "FontAwesome", fontSize: '18px' }} placeholder="&#xf084;&nbsp;&nbsp;Confirmar contraseña (*)" name="confirmPassword"
                                            value={form.confirmPassword} onChange={handleChange} required min="6" />
                                        <button type='button' onClick={togglePasswordVisibility2} className="toggle-password">
                                            {isPasswordShown2 ? 'Ocultar' : 'Mostrar'}
                                        </button>
                                    </div>

                                    <div style={{ textAlign: 'center', color: 'red', width: '100%' }}>
                                        <label>{messageError}</label>
                                    </div>

                                    <div className='col-md-6 col-12'>
                                        <button type="submit" className="btn btn-register rounded-pill font-weight-bold text-white primary-background">
                                            Crear cuenta
                                        </button>
                                    </div>

                                    <div className='col-md-6 col-12' style={{ textAlign: 'center', width: '100%' }}>
                                        {
                                            configAccount.config?.facebook_app_id &&
                                            <FacebookLogin
                                                appId={configAccount.config?.facebook_app_id}
                                                autoLoad={false}
                                                fields="name,email"
                                                callback={responseFacebook}
                                                cssClass="facebook-button-class"
                                                icon="fa-facebook"
                                                textButton="Registrar con Facebook"
                                                disableMobileRedirect={true} />
                                        }
                                    </div>

                                    <div className='col-12 mt-5 mb-5 text-center text-black font-weight-bold'>
                                        ¿Ya tienes cuenta?
                                        <Link className='text-primary-background' style={{ fontSize: '17px' }} to="/ingresar">&nbsp;INGRESA AQUI</Link>
                                    </div>

                                </form> :
                                <form className="signup-form d-flex flex-column align-items-center" onSubmit={simplyRegister}>
                                    <div className="form-group col-12 col-md-6">
                                        <input type="text" className="form-control  rounded-pill" style={{ fontFamily: "FontAwesome", fontSize: '18px' }} placeholder="&#xf007;&nbsp;&nbsp;Nombre completo" name="name"
                                            value={form.name} onChange={handleChange} required />
                                    </div>
                                    <div className="form-group col-12 col-md-6">
                                        <input type="text" className="form-control rounded-pill" style={{ fontFamily: "FontAwesome", fontSize: '18px' }} placeholder="&#xf095;&nbsp;&nbsp;Celular" name="mobile"
                                            value={form.mobile} onChange={handleChange} required />
                                    </div>
                                    <div className="form-group col-12 col-md-6">
                                        <input type="password" className="form-control rounded-pill" style={{ fontFamily: "FontAwesome", fontSize: '18px' }} placeholder="&#xf084;&nbsp;&nbsp;Pin" maxLength={4} name="pin"
                                            value={form.pin} onChange={handleChange} required />
                                    </div>

                                    <div style={{ textAlign: 'center', color: 'red', width: '100%' }}>
                                        <label>{messageError}</label>
                                    </div>

                                    <button type="submit" style={{ fontSize: '18px', fontWeight: 'bold' }} className="btn text-capitalize secondary-background rounded-pill font-weight-bold col-md-6 col-12 text-white">Crear cuenta</button>

                                    <div className='col-12 d-none d-md-flex flex-row justify-content-center mt-5' style={{ textAlign: 'center' }}>
                                        <div className='col-3 col-md-5'><hr /></div>
                                        <div className='col-5 col-md-2'><span style={{ fontSize: '15px', color: 'gray' }}>O registrate con</span></div>
                                        <div className='col-3 col-md-5'><hr /></div>
                                    </div>

                                    <div className='col-12 d-none d-md-flex flex-row justify-content-center align-content-center mt-4' style={{ gap: '50px' }}>
                                        <span style={{ width: '50px', height: '50px' }} className='rounded-circle d-flex align-items-center justify-content-center secondary-background'><Facebook size={25} color='white' /></span>
                                        <span style={{ width: '50px', height: '50px' }} className='rounded-circle d-flex align-items-center justify-content-center secondary-background'><Instagram size={25} color='white' /></span>
                                        <span style={{ width: '50px', height: '50px' }} className='rounded-circle d-flex align-items-center justify-content-center secondary-background'><Google size={25} color='white' /></span>
                                    </div>

                                    <div className='col-12 d-flex flex-row justify-content-center mt-5 mb-5 primary-color font-weight-bold'>
                                        ¿Ya tienes cuenta? &nbsp;&nbsp; <u><Link className='primary-color' style={{ fontSize: '17px' }} to="/ingresar">INGRESA AQUI</Link></u>
                                    </div>
                                </form>
                        }
                    </div>
                </div>
            </section>
        </React.Fragment >
    );
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
    configAccount: state.accountReducer
})

const mapDispatchToProps = (dispatch) => ({
    setLocalStorage: (data) => {
        dispatch(userSetStorageAction(data))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(Register);
