import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';
import { Constants } from '../../Constants';
import './ProductImage.scss';
import close from '../../assets/images/close.svg'

const ProductImage = (props) => {
    return (
        <div className="products-page-gallery">
            <ImageGallery thumbnailPosition={'bottom'} items={props.images.map(item =>
            ({
                id: item.id,
                original: `${item.image?.includes(Constants.BASE_URL_IMAGES) ? item.image : Constants.BASE_URL + item.image}`,
                thumbnail: `${item.image_thumb?.includes(Constants.BASE_URL_IMAGES) ? item.image_thumb : Constants.BASE_URL + item.image_thumb}`
            }))}
                showPlayButton={false} renderFullscreenButton={(onClick, isFullscreen) => {
                    return (
                        <div className={`image-gallery-icon-fullscreen ${isFullscreen ? 'zoom-out' : 'zoom-in'}`} onClick={onClick}>
                            {isFullscreen ?
                                <img src={close} alt='product' data-toggle="tooltip" data-placement="top" title="Salir pantalla completa" />
                                : null
                            }
                        </div>
                    );
                }}
            />
        </div>
    );
}

export default ProductImage;
