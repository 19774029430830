import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Loading from '../components/loading/Loading';
import GoogleMap from '../components/google-maps/GoogleMap';
import contact_img from '../assets/images/contact_banner.jpg';
import { toast } from 'react-toastify';

const title = 'Contacto'

const Contact = ({ sessionProps, configAccount }) => {

    const [loading, setLoading] = useState(false)
    const [isLogged, setIsLogged] = useState(sessionProps?.account?.email ? true : false)
    const [sendEmail, setSendEmail] = useState(configAccount?.config?.contact_email ?? 'contacto.sayasoftware@gmail.com')
    const [form, setForm] = useState({
        name: '',
        email: sessionProps?.account?.email ?? '',
        phone: '',
        message: '',
        subject: ''
    });

    useEffect(() => {
        if (configAccount?.config?.title_tabs) {
            document.title = configAccount.config.title_tabs + ' - ' + title;
        }
        if (configAccount?.config?.contact_email) {
            setSendEmail(configAccount?.config?.contact_email)
        }
        toast.configure();
    }, [configAccount.config])

    const handleChange = event => {
        const { name, value } = event.target;
        setForm(prev => ({
            ...prev,
            [name]: value
        }));
    }

    const sendContact = async (event) => {
        event.preventDefault();
        setLoading(true);
        window.Email.send({
            SecureToken: "7073df93-8259-490e-9d43-9b869f755fc4",
            To: sendEmail,
            From: form.email,
            Subject: form.subject ?? "Contacto desde la web",
            Body: `${form.name} ha enviado un mensaje. <br>
            Su email es ${form.email} y su número de teléfono es ${form.phone}. <br>
            El mensaje es el siguiente: <br><br> ${form.message}`
        }).then(
            message => {
                setLoading(false);
                let alertText = 'Ocurrió un error, reitentar';
                if (message !== 'OK') throw new Error(message);
                alertText = 'Mensaje enviado con éxito, en breve nos pondremos en contacto';
                setIsLogged(sessionProps?.account?.email ? true : false);
                setForm({
                    name: '',
                    email: sessionProps.account.email ? sessionProps.account : '',
                    phone: '',
                    message: '',
                })
                toast(alertText, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        ).catch(error => {
            setLoading(false)
            console.log(error)
        });
    }

    return (
        <React.Fragment>
            {loading && <Loading />}
            <section className="contact-area">
                <div id="banner-img-hero">
                    <div className="col-12 pl-0 pr-0 d-flex position-relative justify-content-center align-items-center bg-image">
                        <img src={contact_img} className='contact-banner' style={{ minWidth: '100%', objectFit: 'cover' }} alt="banner" />
                        <div class="mask text-light d-flex justify-content-center flex-column text-center position-absolute h-100 w-100" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
                            <h2 className='text-white font-weight-bold'>Informaci&oacute;n de contacto</h2>
                            <div className='col-6 col-md-3 justify-content-center align-self-center'>
                                <button onClick={() => window.scrollTo({
                                    behavior: 'smooth',
                                    top: 1200,
                                    left: 0
                                })} className='w-100 rounded-pill primary-background primary-color button-third-color font-weight-bold align-self-center border-0 p-2' style={{ fontSize: 18, fontWeight: 'bold' }}>
                                    Contáctanos
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 col-12">
                            <div className="contact-info">
                                <ul className="contact-list d-flex flex-column">
                                    <li className='dotted-gradient col-12 col-md-12 d-flex flex-row rounded rounded-md-pill justify-content-between align-items-center items-contact pr-0 pl-0 pl-md-1 text-center text-md-left'>
                                        <span className='contact-icon p-4' ><i className="far fa-envelope"></i></span>
                                        <span className='col-10 d-flex flex-column pr-0 pl-0'>
                                            <b><p>Correo electronico</p></b>
                                            {
                                                configAccount.config?.contact_email &&
                                                <a className='align-self-center align-self-md-start' href={`mailto:${configAccount.config.contact_email}`}>
                                                    {configAccount.config.contact_email}
                                                </a>
                                            }
                                        </span>
                                    </li>
                                    <li className='dotted-gradient col-12 col-md-12 d-flex flex-row rounded rounded-md-pill justify-content-between align-items-center items-contact text-center text-md-left'>
                                        <span className='contact-icon p-4' ><i className="fas fa-phone"></i></span>
                                        <span className='col-10 d-flex flex-column pr-0 pl-0'>
                                            <b><p>Telefono</p></b>
                                            {
                                                configAccount.config?.contact_phone &&
                                                <a className='align-self-center align-self-md-start' href={`tel:${configAccount.config.contact_phone}`}>
                                                    {configAccount.config.contact_phone}
                                                </a>
                                            }
                                        </span>
                                    </li>
                                    <li className='dotted-gradient col-12 col-md-12 d-flex flex-row rounded rounded-md-pill justify-content-between align-items-center items-contact text-center text-md-left'>
                                        <span className='contact-icon p-4' ><i className="fas fa-map-marker-alt"></i></span>
                                        <span className='col-10 d-flex flex-column pr-0 pl-0'>
                                            <b><p>Direccion</p></b>
                                            {configAccount.config?.contact_address ? configAccount.config.contact_address : ''}
                                        </span>
                                    </li>
                                    <li className='col-12 col-md-12 d-flex flex-row rounded rounded-md-pill justify-content-between align-items-center items-contact text-center text-md-left'>
                                        <span className='contact-icon p-4' ><i className="fa fa-globe"></i></span>
                                        <span className='col-10 d-flex flex-column pr-0 pl-0'>
                                            <b><p>Pagina web</p></b>
                                            {
                                                configAccount.config?.footer_company_web &&
                                                <a className='align-self-center align-self-md-start' href={`mailto:${configAccount.config.footer_company_web}`}>
                                                    {configAccount.config.footer_company_web}
                                                </a>
                                            }
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-9 col-12 contact-box p-0">
                            {
                                configAccount.config?.contact_map_lat &&
                                <GoogleMap
                                    lat={configAccount.config.contact_map_lat}
                                    lng={configAccount.config.contact_map_lng}
                                />
                            }
                        </div>
                    </div>
                </div>
                <div className='intercalate-background'>
                    <div className='container'>
                        <div className='row'>
                            <div className="col-12 mt-5 mb-5">
                                <div className="contact-form text-center">
                                    <form onSubmit={sendContact}>
                                        <div className='row ml-1 ml-md-0'>
                                            <div className='col-12 text-center justify-content-center mb-2'>
                                                <h4 style={{ fontWeight: 'bold' }} className='text-black'>Enviar mensaje</h4>
                                            </div>
                                            <p className='text-black' style={{ textAlign: 'start' }}>¡Estamos encantados de saber de ti! Por favor, utiliza este formulario para ponerte en contacto con nosotros. Ya sea que tengas preguntas o comentarios.</p>
                                        </div>
                                        <div className="row mt-4 p-2 justify-content-between" >
                                            <div className="col-md-6 p-0 pr-md-2">
                                                <div className="form-group">
                                                    <input type="text" name="name" className="form-control rounded-pill border-secondary-color" required={true} placeholder="Nombre completo"
                                                        value={form.name} onChange={handleChange} />
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                            </div>

                                            {
                                                <div className="col-md-6 p-0 pl-md-2">
                                                    <div className="form-group">
                                                        <input type="email" name="email" className="form-control rounded-pill border-secondary-color" required={true} placeholder="Correo electronico"
                                                            value={form.email} onChange={handleChange} />
                                                        <div className="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                            }

                                            <div className="col-md-6 p-0 pr-md-2">
                                                <div className="form-group">
                                                    <input type="text" name="phone" className="form-control rounded-pill border-secondary-color" required={true} placeholder="Teléfono"
                                                        value={form.phone} onChange={handleChange} />
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                            </div>

                                            <div className="col-md-6 p-0 pl-md-2">
                                                <div className="form-group" >
                                                    <input type="text" name="subject" className="form-control rounded-pill border-secondary-color" required={true} placeholder="Asunto"
                                                        value={form.subject} onChange={handleChange} />
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                            </div>

                                            <div className="col-md-12 p-0">
                                                <div className="form-group">
                                                    <textarea name="message" cols="30" rows="4" required={true} className="form-control border-secondary-color" placeholder="Mensaje"
                                                        value={form.message} onChange={handleChange} />
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                            </div>

                                            <div className="col-6 col-md-12 p-0">
                                                <button type="submit" className="border-0 p-2 w-100 button-third-color primary-background primary-color rounded-pill font-weight-bold text-capitalize">Enviar</button>
                                                <div id="msgSubmit" className="h3 text-center hidden"></div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
    configAccount: state.accountReducer,
});

export default connect(mapStateToProps, null)(Contact);
