/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './AuctionDetail.scss';
import { getAllAuctions as getAllAuctionsService, getAuctionInPerson } from '../../services/AuctionServices';
import Loading from '../loading/Loading';
import moment from 'moment';
import 'moment/locale/es';
import { Constants } from '../../Constants';
import { connect } from 'react-redux'
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { logoutAction } from '../../redux/actions/UserActions';
import calendar from '../../assets/icons/calendar.svg';
import { Carousel } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import close from '../../assets/images/close.svg'

class AuctionDetail extends Component {
    state = {
        id: this.props.match.params.id,
        auction: {
            title: '',
            description: '',
            dateDescription: '',
            lots: {},
            secondsStart: '',
            minutesStart: '',
            pending: false,
            catalog: '',
            images: []
        },
        loading: false,
        intervalAuction: null,
        intervalCountDown: null,
        intervalGetDateTime: null,
        intervalCountDownStart: null,
        closingLots: false,
        positionY: 0,
        title: `Subasta #${this.props.match.params.id}`,
        timestamp: null,
        apiKey: null,
        showImagesFullscreen: null,
    }

    componentDidMount = async () => {
        let title = this.state.title;
        if (this.props.configAccount.config?.title_tabs) {
            title = this.props.configAccount.config.title_tabs + ' - ' + title;
        }
        document.title = title;
        toast.configure();
        moment.locale('es');
        await this.fetchAuction();
    }

    componentDidUpdate = async (prevProps) => {
        if (prevProps.configAccount.config !== this.props.configAccount.config) {
            if (this.props.configAccount.config?.title_tabs !== null) {
                document.title = this.props.configAccount.config.title_tabs + ' - ' + this.state.title;
            }
        }
    }

    componentWillReceiveProps = async (newProps) => {
        if (this.state.id !== newProps.match.params.id) {
            await this.setState({
                id: newProps.match.params.id
            })
            this.fetchAuction();
        }
    }

    fetchAuction = async (showLoading = true) => {
        showLoading && await this.setState({ loading: true });
        try {
            let token = this.props.sessionProps.account && this.props.sessionProps.account.token ? this.props.sessionProps.account.token : null;
            let response = await getAuctionInPerson({ id: this.state.id, token: token, interval: 0 });
            if (showLoading && response.data.data.version !== Constants.VERSION) {
                toast.error("La versión de la aplicación ha sido actualizada, refresque la página por favor", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
            let lots = response.data.data.lots;
            await this.setState({
                auction: {
                    ...this.state.auction,
                    ...response.data.data,
                    dateTime: response.data.data.dateTime.date,
                    lots,
                    status: '',
                },
                timestamp: response.data.timestamp
            });
            if (showLoading && this.props.match.params.y !== undefined) {
                window.scrollTo(0, this.props.match.params.y);
            }
            setTimeout(() => {
                this.setState({
                    loading: false,
                })
            }, 1000)
        } catch (error) {
            await this.setState({ loading: false });
            if (error?.response?.status === 404) {
                const { data } = await getAllAuctionsService();
                if (data?.data?.inProgress && data?.data?.inProgress.length) {
                    let id = data.data.inProgress[0].id;
                    this.props.history.push(`/subastas/${id}`);
                }
                else {
                    this.props.history.push(`/`);
                }
            }
        }
    }

    capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    card = ({ item, index }) => (
        <React.Fragment>
            <div className='col-12 col-md-4'>
                <div className=' card-lot'>
                    <div style={{ position: 'relative' }}>
                        <div className='p-0'>
                            {
                                item.extraImages ?
                                    <div className="single-collections-box">
                                        <Carousel variant="dark" interval={null}>
                                            {item.extraImages.map((image, indexImg) =>
                                                <Carousel.Item>
                                                    <img
                                                        onClick={() => {
                                                            this.setState({ showImagesFullscreen: indexImg, fullscrenLotId: item.id })
                                                        }}
                                                        style={{ cursor: 'pointer' }}
                                                        className="d-block w-100"
                                                        src={`${image.image}`}
                                                        alt="Lote sin imagen"
                                                    />
                                                </Carousel.Item>
                                            )}
                                        </Carousel>
                                    </div>
                                    :
                                    <a>
                                        <div className="product-image">
                                            {
                                                item.image_lot?.includes(Constants.BASE_URL_IMAGES) ?
                                                    <img src={`${item.image_lot}`} alt="Lote sin imagen" />
                                                    : <img src={`${Constants.BASE_URL}${item.image_lot}`} alt="Lote sin imagen" />
                                            }
                                        </div>
                                    </a>
                            }
                        </div>

                        <div className="text-left p-3">
                            <h6 className='font-weight-bold mb-0' >Lote: {item.id_lot}</h6>
                            <p className='title-lot'>
                                {item.title}
                            </p>
                            {
                                item.description === null || item.description === '' ?
                                    <p className="description-without">-</p>
                                    : <p className="description-with" dangerouslySetInnerHTML={{ __html: item.description }} />
                            }
                            <p className="quantity-lot">
                                <b>Cantidad:</b> {item.quantity}
                            </p>
                            <p className="date-close-and-price"><b>Base:</b>
                                {
                                    item.price_base > 0 ?
                                        ` ${this.state.auction.moneySymbol} ${new Intl.NumberFormat("de-DE").format(item.price_base)}` : ' Sin base'
                                }
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );

    render() {
        return (
            <section className={`${this.props.sessionProps.account !== null ? 'pb-60' : 'ptb-60'}`} id="auction-detail-in-person">
                {this.state.loading && <Loading />}
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-12 text-center">
                            <div className="text-left d-flex flex-row mt-5">
                                <span className='rounded-pill mr-4 border-title-third text-primary-background' ></span>
                                <h1 className='font-weight-bold'><span className='text-black'>{this.state.auction.title}</span></h1>
                            </div>
                        </div>
                    </div>

                    <div className="box-subastas">
                        <div className="row box-top-subastas">
                            <div className='col-12 col-md-9 d-flex align-items-center desktop'>
                                <img src={calendar} alt="calendar" className="" style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', width: '50px', height: '50px', marginRight: '1rem' }} />
                                <div style={{ marginLeft: '1rem' }}>
                                    <label>FECHA</label>
                                </div>
                                <div style={{ marginLeft: '1rem' }}>
                                    <label style={{ color: '#323232', fontWeight: 'bold' }}>
                                        {
                                            !this.state.loading &&
                                                this.state.auction.date_from ? `
                                                ${this.capitalize(moment(this.state.auction.date_from).format('dddd D'))} de 
                                                ${moment(this.state.auction.date_from).format('MMMM')}, 
                                                ${moment(this.state.auction.date_from).format('HH:mm')} hs`
                                                : 'Próximamente'
                                        }
                                    </label>
                                </div>
                            </div>
                            <div className="col-12 col-md-9 mobile date-from-to">
                                <img src={calendar} alt="calendar" className=""
                                    style={{ margin: 'auto', alignItems: 'center', justifyContent: 'center', display: 'flex', width: '50px', height: '50px' }} />
                                <label style={{ marginBottom: '0rem', marginTop: '1rem' }}>FECHA</label>
                                <label style={{ color: '#323232', fontWeight: 'bold' }}>
                                    {
                                        !this.state.loading &&
                                            this.state.auction.date_from ? `
                                                ${this.capitalize(moment(this.state.auction.date_from).format('dddd D'))} de 
                                                ${moment(this.state.auction.date_from).format('MMMM')}, 
                                                ${moment(this.state.auction.date_from).format('HH:mm')} hs`
                                            : 'Próximamente'
                                    }
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className='row mt-5'>
                        <div className="col-12"
                            dangerouslySetInnerHTML={{ __html: this.state.auction.description }} />
                    </div>

                    {
                        this.state.auction.catalog !== null && this.state.auction.catalog !== '' ? (
                            <div className="container video-youtube" style={{ padding: 0, marginTop: '2rem' }}>
                                <ReactPlayer controls={true} url={this.state.auction.catalog} width="100%" height="100%" />
                            </div>
                        ) : null
                    }

                    <div style={{ marginTop: '2rem' }}>
                        <div className="row pl-2 pr-2">
                            {
                                Object.values(this.state.auction.lots).map((item, index) =>
                                    <this.card item={item} index={index} />
                                )
                            }
                        </div>
                    </div>

                    <div style={{ marginTop: '2rem' }}>
                        <div className="row pl-2 pr-2">
                            {
                                this.state.auction.images.map((item, index) => (
                                    <div className='col-12 col-md-4' style={{ marginBottom: '15px' }}>
                                        {
                                            item.image.includes(Constants.BASE_URL_IMAGES) ?
                                                <img src={item.image} alt=""
                                                    style={{ width: '100%', height: 'auto', cursor: 'zoom-in' }} onClick={() => {
                                                        this.setState({ showImagesFullscreen: index, fullscrenLotId: null })
                                                    }} /> :
                                                <img src={`${Constants.BASE_URL}${item.image}`} alt=""
                                                    style={{ width: '100%', height: 'auto', cursor: 'zoom-in' }} onClick={() => {
                                                        this.setState({ showImagesFullscreen: index, fullscrenLotId: null })
                                                    }} />
                                        }
                                        <p>
                                            {item.description}
                                        </p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    {
                        this.state.showImagesFullscreen !== null ?
                            <div className='images-fullscreen'>
                                <Carousel variant="dark" interval={null} defaultActiveIndex={this.state.showImagesFullscreen} >
                                    {!this.state.fullscrenLotId && this.state.auction.images.map(image =>
                                        <Carousel.Item>
                                            <div style={{ position: 'absolute', right: '0.5rem', top: '0.5rem', zIndex: 9, cursor: 'zoom-out', padding: '0.5rem' }}>
                                                <img
                                                    src={close}
                                                    alt=""
                                                    style={{ background: '#fff', borderRadius: '100%' }}
                                                    onClick={() => {
                                                        this.setState({ showImagesFullscreen: null })
                                                    }} />
                                            </div>
                                            {
                                                image.image.includes(Constants.BASE_URL_IMAGES) ?

                                                    <img

                                                        src={image.image}
                                                        alt="Lote sin imagen"
                                                        style={{ cursor: 'zoom-out' }}
                                                        onClick={() => {
                                                            this.setState({ showImagesFullscreen: null })
                                                        }
                                                        } />
                                                    :
                                                    <img
                                                        src={`${Constants.BASE_URL}${image.image}`}
                                                        alt="Lote sin imagen"
                                                        style={{ cursor: 'zoom-out' }}
                                                        onClick={() => {
                                                            this.setState({ showImagesFullscreen: null })
                                                        }}
                                                    />
                                            }
                                        </Carousel.Item>
                                    )}
                                    {this.state.fullscrenLotId && Object.values(this.state.auction.lots)
                                        .find(lot => lot.id === this.state.fullscrenLotId)?.extraImages.map(image =>
                                            <Carousel.Item>
                                                <div style={{ position: 'absolute', right: '0.5rem', top: '0.5rem', zIndex: 9, cursor: 'zoom-out', padding: '0.5rem' }}>
                                                    <img
                                                        src={close}
                                                        alt=""
                                                        style={{ background: '#fff', borderRadius: '100%' }}
                                                        onClick={() => {
                                                            this.setState({ showImagesFullscreen: null })
                                                        }} />
                                                </div>
                                                {
                                                    image.image.includes(Constants.BASE_URL_IMAGES) ?
                                                        <img
                                                            src={`${image.image}`}
                                                            alt="Lote sin imagen"
                                                            style={{ cursor: 'zoom-out' }}
                                                            onClick={() => {
                                                                this.setState({ showImagesFullscreen: null })
                                                            }}
                                                        /> :
                                                        <img
                                                            src={`${Constants.BASE_URL}${image.image}`}
                                                            alt="Lote sin imagen"
                                                            style={{ cursor: 'zoom-out' }}
                                                            onClick={() => {
                                                                this.setState({ showImagesFullscreen: null })
                                                            }}
                                                        />
                                                }
                                            </Carousel.Item>
                                        )}
                                </Carousel>
                            </div> : null
                    }
                </div>
            </section >
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
    configAccount: state.accountReducer
})

const mapDispatchToProps = (dispatch) => ({
    logout: () => {
        dispatch(logoutAction());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(AuctionDetail);