import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useAccount } from '../../../store/account';
import GoTop from '../../Shared/GoTop';
import './styles.scss';
import { Constants } from '../../../Constants';
import { Facebook, Instagram } from 'react-bootstrap-icons';

const Footer = ({ sessionProps }) => {
    const accountStore = useAccount();
    return (
        <footer className="footer-background">
            <div className='container'>
                <div className="row footer-information">
                    <a href={`https://wa.me/${accountStore.config?.whatsapp}`}
                        className="whatsapp" rel='noopener noreferrer' target="_blank"> <i className="fab fa-whatsapp"></i>
                    </a>
                    <div className={`d-none d-md-block col-md-2`}>
                        <Link to="/">
                            <img alt='logo' style={{ maxWidth: '100%', marginBottom: '1rem' }}
                                src={Constants.BASE_URL + accountStore.config?.logo_web}></img>
                        </Link>
                        {
                            accountStore.config?.facebook ? (
                                <div style={{ marginBottom: '0.5rem' }}>
                                    <a href={accountStore.config?.facebook || ''} target='_blank' rel="noopener noreferrer"
                                        style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                        <Facebook className='footer-text-color' size={20} />
                                        Facebook
                                    </a>
                                </div>) : null
                        }
                        {
                            accountStore.config?.instagram ? (
                                <div>
                                    <a href={accountStore.config?.instagram || ''} target='_blank' rel="noopener noreferrer"
                                        style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                        <Instagram className='footer-text-color' size={20} />
                                        Instagram
                                    </a>
                                </div>) : null
                        }
                    </div>
                    <div className={`d-none d-md-block col-md-1`} />
                    <div className={`col-6 col-md-3 footer-section`}>
                        <h6 className="font-weight-bold mb-4">Contacto</h6>
                        <p>{accountStore.config?.footer_text_1}</p>
                        <p>{accountStore.config?.footer_text_2}</p>
                        <p>{accountStore.config?.footer_text_3}</p>
                        <p>{accountStore.config?.footer_text_4}</p>
                    </div>
                    <div className="col-6 col-md-3 footer-section">
                        <h6 className=" font-weight-bold mb-4">Cuenta</h6>
                        <Link to={sessionProps?.account?.id ? "/mi-cuenta/1" : "/ingresar"}><p>Mi cuenta</p></Link>
                        <Link to={!sessionProps?.account?.id ? "/ingresar" : `/mi-cuenta/2/`}>
                            <p>Mis ofertas</p>
                        </Link>
                        <Link to={!sessionProps?.account?.id ? "/ingresar" : `/mi-cuenta/3/`}>
                            <p>Mis compras</p>
                        </Link>
                    </div>
                    <div className="col-6 col-md-3 footer-section">
                        <h6 className=" font-weight-bold mb-4">Seguridad</h6>
                        {accountStore?.config?.include_about_us ? (
                            <Link to="/nosotros"><p>Nosotros</p></Link>
                        ) : null}
                        {accountStore?.config?.include_page_buy ? (
                            <Link to='/comprar'><p>Comprar</p></Link>
                        ) : null}
                        {accountStore?.config?.include_page_sell ? (
                            <Link to='/vender'><p>Vender</p></Link>
                        ) : null}
                        {accountStore?.config?.include_page_sell_buy ? (
                            <Link to='/comprar-vender'><p>Comprar/Vender</p></Link>
                        ) : null}
                        {accountStore?.config?.include_page_conditions ? (
                            <Link to='/terminos-condiciones'><p>Términos & condiciones</p></Link>
                        ) : null}
                        {accountStore?.config?.include_page_faq ? (
                            <Link to='/ayuda'><p>Ayuda</p></Link>
                        ) : null}
                        <Link to='/contacto'><p>Contacto</p></Link>
                    </div>
                    <div className='col-6 mobile footer-section'>
                        {
                            accountStore.config?.facebook ? (
                                <div style={{ marginBottom: '0.5rem', marginTop: '1rem' }} >
                                    <a href={accountStore.config?.facebook || ''} target='_blank' rel="noopener noreferrer"
                                        style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', justifyContent: 'center' }}>
                                        <Facebook className='footer-text-color' size={20} />
                                        Facebook
                                    </a>
                                </div>
                            ) : null
                        }
                        {
                            accountStore.config?.instagram ? (
                                <div>
                                    <a href={accountStore.config?.instagram || ''} target='_blank' rel="noopener noreferrer"
                                        style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', justifyContent: 'center' }}>
                                        <Instagram className='footer-text-color' size={20} />
                                        Instagram
                                    </a>
                                </div>
                            ) : null
                        }
                    </div>
                </div>
            </div>
            <div className='footer-bottom' style={{ borderTop: '1px solid #e8e8e8' }}>
                <div className='container'>
                    <div className='row' style={{ padding: '0.5rem 0rem' }}>
                        <div className="col-12 text-center">
                            <span style={{ fontSize: '12px' }}>
                                @ Copyright {new Date().getFullYear()}.&nbsp; All right reserved&nbsp;
                                <u>
                                    <a target="_blank" rel="noopener noreferrer" className="text-black link"
                                        href={accountStore.config?.footer_company_web || ''}>
                                        {accountStore.config?.footer_company_name}
                                    </a>
                                </u>
                            </span>
                        </div>
                        <div className="col-12 text-center">
                            <span style={{ fontSize: '12px' }}>
                                Powered by &nbsp;
                                <u>
                                    <a target="_blank" rel="noopener noreferrer" className="text-black link" href="https://sayasoftware.com">SayaSoftware</a>
                                </u>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <GoTop scrollStepInPx="250" />
        </footer >
    );
};

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
    configAccount: state.accountReducer
})

export default connect(mapStateToProps)(Footer);
