import React from 'react';
import { Link } from 'react-router-dom';
import { Telephone } from 'react-bootstrap-icons';
import { Constants } from '../../../Constants';
import { useAccount } from '../../../store/account';

const SecondaryMenu = () => {
  const accountStore = useAccount();

  return (
    <div className='secondary-menu secondary-background d-flex flex-row justify-content-center align-items-center'>

      <div className='p-5 container position-relative'>
        {
          window.socket_io_customer === 'araujo_subastas' ? (
            <div className='d-md-flex d-none personal-information'>
              <p>
                Rematadores desde 1963
              </p>
            </div>
          ) : null
        }
        <div className='d-flex justify-content-center'>
          <Link to="/"><img alt='logo' src={Constants.BASE_URL + accountStore.config?.logo_web} style={{ width: '250px' }}></img></Link>
        </div>
        {
          window.socket_io_customer === 'araujo_subastas' ? (
            <div className='d-md-flex d-none personal-information right'>
              <p>
                1ros. Online desde 2017
              </p>
            </div>
          )
            :
            accountStore.config?.contact_phone &&
            <div className='d-md-flex d-none phone-information'>
              <a className='secondary-color' href={`tel://${accountStore?.config?.contact_phone}`}>
                <span className='rounded-circle d-flex align-items-center justify-content-center social-networks'>
                  <Telephone className='text_color_with_primary' size={20} />
                </span>
                {accountStore.config?.contact_phone}
              </a>
            </div>
        }

        {
          window.socket_io_customer === 'araujo_subastas' ? (
            <div className='mobile personal-information-mobile'>
              <p>
                Rematadores desde 1963 <br />
                1ros. Online desde 2017
              </p>
            </div>
          ) : null
        }
      </div>
    </div>
  );
};

export default SecondaryMenu;