import React from 'react'

const ItemBuySell = ({number, title, description, Icon}) => {
  return (
    <div className={`col-12 col-md-5 d-flex align-items-end ${number % 2 ? 'pr-0' : 'pl-0'}  pb-0`} style={{marginTop: '70px', backgroundColor: '#919DAC'}}>
        <span className='button-third-color text-center text-white position-absolute d-flex align-items-center justify-content-center' style={{width: 70, height: 70, right: number % 2 ? 40 : undefined, left: number % 2 ? undefined : 40, top: -25}} >
            <h1 className='display-5 font-weight-bold'>{ "0"+number }</h1>
        </span>
        <div style={{backgroundColor: '#fff'}} className={`${number % 2 ? 'ml-5 mt-4 text-left d-flex justify-content-center flex-column align-items-start' : 'mr-5 mt-4 text-right d-flex flex-column align-items-end'} `}>
            <div className={`${number % 2 ? 'ml-1' : 'mr-1'} col-10 mt-2 pt-2`}>
                <h6 className='display-7 font-weight-bold'>{title} </h6>
            </div>
            <div className={`${number % 2 ? 'ml-1' : 'mr-1'} col-11`}>
                <p style={{fontSize: '14px', height: '105px'}} className='mb-0'>
                {
                    description
                }
                </p>
            </div>
        </div>
    </div>
  )
}

export default ItemBuySell