import React from 'react';
import { Fragment } from 'react';
import { useAccount } from '../../../store/account';
import MobileMenu from './mobile';
import PrimaryMenu from './primary-menu';
import SecondaryMenu from './secondary-menu';
import './styles.scss';

const Menu = () => {
  const accountStore = useAccount();

  return <div className='menu'>
    <SecondaryMenu />
    <PrimaryMenu />
  </div>;
};

export default Menu;
