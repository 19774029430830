import React, { Component } from 'react';
import AuctionInPersonDetail from '../../components/auction-in-person/AuctionDetail';

class AuctionInPersonDetails extends Component {
  render() {
    return (
      <React.Fragment>
        <AuctionInPersonDetail {...this.props} />
      </React.Fragment>
    );
  }
}

export default AuctionInPersonDetails;
