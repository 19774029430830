import React, { useEffect, useState } from "react"
import './styles.scss'
import { connect } from "react-redux"
import { useAccount } from "../../../store/account";
import axios from "axios";
import { Constants } from "../../../Constants";

const ActiveNotifications = ({ hideHrBottom, sessionProps }) => {
    const accountStore = useAccount();
    const [notifyEmail, setNotifyEmail] = useState(0)
    const [notifyWpp, setNotifyWpp] = useState(0)
    const [first, setFirst] = useState(true)

    useEffect(() => {
        fetchNotifications()
    }, [])

    useEffect(() => {
        if (!first) {
            saveNotifications()
        }
    }, [notifyEmail, notifyWpp])

    const fetchNotifications = async () => {
        try {
            const response = await axios.get(`${Constants.BASE_URL}myaccount/notifications`, {
                headers: {
                    Authorization: sessionProps?.account?.token
                }
            }
            );
            if (response?.data?.data) {
                await setNotifyEmail(response?.data?.data?.notify_email)
                await setNotifyWpp(response?.data?.data?.notify_wpp)
                await setFirst(false)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const saveNotifications = async () => {
        try {
            await axios.post(`${Constants.BASE_URL}myaccount/active-notifications`, {
                notify_email: notifyEmail,
                notify_wpp: notifyWpp
            }, {
                headers: {
                    Authorization: sessionProps?.account?.token
                }
            }
            );
        } catch (error) {
            console.error(error)
        }
    }

    if (!accountStore?.config?.use_notifications) {
        return null
    }

    return (
        <div>
            <hr />
            <h5> Nueva funcionalidad</h5>
            <p>Notificarme cuando me superen la oferta</p>
            <div>
                <input type="checkbox" id="emailCheckbox" name="email"
                    checked={notifyEmail}
                    onChange={() => setNotifyEmail(!notifyEmail)}
                />
                <label for="emailCheckbox"><i class="fas fa-envelope icon-checkbox"></i>Activar email</label>
            </div>
            <div>
                <input type="checkbox" id="whatsappCheckbox" name="whatsapp"
                    checked={notifyWpp}
                    onChange={() => setNotifyWpp(!notifyWpp)}
                />
                <label for="whatsappCheckbox"><i class="fab fa-whatsapp icon-checkbox"></i>Activar WhatsApp</label>
            </div>
            {
                !hideHrBottom ? <hr /> : null
            }
        </div >
    )
}

const mapStateToProps = (state) => ({
    configAccount: state.accountReducer,
    sessionProps: state.userReducer,
})

export default connect(mapStateToProps)(ActiveNotifications);