/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './AuctionDetail.scss';

import { getAuction, getAllAuctions as getAllAuctionsService, getFavorites } from '../../services/AuctionServices';
import Loading from '../loading/Loading';
import moment from 'moment';
import 'moment/locale/es';
import { Constants } from '../../Constants';
import { connect } from 'react-redux'
import { addOffer, deleteOffer, notifyUser } from '../../services/OffersServices';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { logoutAction } from '../../redux/actions/UserActions';
import { confirmAlert } from 'react-confirm-alert';
import calendar from '../../assets/icons/calendar.svg';
import downloadCatalog from '../../assets/icons/download_catalog.png';
//import download from '../../assets/icons/download.svg';
import { Carousel } from 'react-bootstrap';
import { addToFavoriteService } from '../../services/LotsServices';
import { XCircle } from 'react-bootstrap-icons';
import axios from 'axios';
import ReactPlayer from 'react-player';
import ActiveNotifications from '../Shared/activeNotifications';

class AuctionDetail extends Component {
    state = {
        id: this.props.match.params.id,
        auction: {
            title: '',
            description: '',
            dateDescription: '',
            lots: {},
            secondsStart: '',
            minutesStart: '',
            pending: false,
            catalog: '',
            catalogUrlAutomatic: null,
            auction_type: null,
        },
        loading: false,
        intervalAuction: null,
        intervalCountDown: null,
        intervalGetDateTime: null,
        intervalCountDownStart: null,
        closingLots: false,
        positionY: window?.scrollY || 0,
        title: `Subasta #${this.props.match.params.id}`,
        timestamp: null,
        tab: 'all',
        favoritesLotsId: [],
        piesocket: null,
    }

    componentDidMount = async () => {
        let title = this.state.title;
        if (this.props.configAccount.config?.title_tabs) {
            title = this.props.configAccount.config.title_tabs + ' - ' + title;
        }
        document.title = title;
        // scroll to title section
        window.scrollTo(0, 200);

        toast.configure();
        moment.locale('es');
        await this.fetchAuction();
        this.fetchFavorites();
        let interval = setInterval(() => {
            this.fetchAuction(false, 1);
        }, 15000);
        await this.setState({
            intervalAuction: interval
        });
        this.subscribeSocket(this.props.configAccount?.config?.socket_api_key);
        document.addEventListener("scroll", () => {
            this.setState({
                positionY: window.scrollY
            })
        });
    }

    componentDidUpdate = async (prevProps) => {
        if (prevProps.configAccount.config !== this.props.configAccount.config) {
            if (this.props.configAccount.config?.title_tabs !== null) {
                document.title = this.props.configAccount.config.title_tabs + ' - ' + this.state.title;
            }
            if (this.props.configAccount.config?.socket_api_key !== null) {
                this.subscribeSocket(this.props.configAccount?.config?.socket_api_key);
            }
        }
    }

    componentWillReceiveProps = async (newProps) => {
        if (this.state.id !== newProps.match.params.id) {
            await this.setState({
                id: newProps.match.params.id
            })
            this.fetchAuction();
        }
    }

    subscribeSocket = async (apikey) => {
        if (this.state.piesocket !== null || !apikey) {
            return
        }
        let piesocket = new WebSocket(apikey);
        await this.setState({
            piesocket
        });
        console.log('Connected to the socket server');
        piesocket.onmessage = async (message) => {
            let data = JSON.parse(message.data);
            if (data.socket_io_customer === window.socket_io_customer) {
                let lot = data.lot;
                if (lot && lot.id_remate === this.state.auction.id) {
                    let lots = this.state.auction.lots;
                    let id_lot = lot.id;
                    if (lot.id_lot.includes('-A')) {
                        id_lot = lot.id_lot.replace('-A', '.1')
                    }
                    else if (lot.id_lot.includes('-B')) {
                        id_lot = lot.id_lot.replace('-B', '.2')
                    }
                    else if (lot.id_lot.includes('-C')) {
                        id_lot = lot.id_lot.replace('-C', '.3')
                    }
                    if (this.state.auction.force_order) {
                        id_lot = lot.force_order;
                    }
                    else {
                        id_lot = parseFloat(id_lot).toFixed(2);
                    }
                    if (lots[id_lot].manualOffer > lot.manualOffer && !lots[id_lot].is_delete_offer) {
                        lot.manualOffer = lots[id_lot].manualOffer;
                    }

                    // avisa que la oferta fue superada
                    const userId = this.props.sessionProps.account?.id;
                    if (userId !== null && userId === lots[id_lot].bestUserIdOffer && userId !== lot.bestUserIdOffer &&
                        (!lots[lot.id]?.automaticOffer || lots[lot.id]?.automaticOffer < lot.nextOffer)) {
                        toast.warning("Tu oferta ha sido superada en el lote #" + lot.id_lot + ", revisar", {
                            position: toast.POSITION.BOTTOM_CENTER,
                            pauseOnHover: false,
                            duration: 20000,
                            onClick: () => {
                                document.getElementById(`lot-${lot.id}`).scrollIntoView();
                                window.scrollBy(0, -100);
                            }
                        })
                    }

                    lot.image_lot = lots[id_lot]?.image_lot;
                    lot.image_remision = lots[id_lot]?.image_remision;
                    lot.participation = lot.participations.filter(item => item === this.props.sessionProps?.account?.id).length > 0 ? true : null;
                    lot.automaticOffer = lots[id_lot]?.automaticOffer
                    lot.offerType = lots[id_lot]?.offerType || 1;
                    if (this.state.auction.force_order) {
                        lots[lot.force_order] = lot;
                    }
                    else {
                        lots[id_lot] = lot;
                    }
                    await this.setState({
                        auction: {
                            ...this.state.auction,
                            lots: lots
                        },
                    });
                }
            }
        }
    }

    fetchAuction = async (showLoading = true, interval = 0) => {
        showLoading && await this.setState({ loading: true });
        try {
            let token = this.props.sessionProps.account && this.props.sessionProps.account.token ? this.props.sessionProps.account.token : null;
            let response = await getAuction({ id: this.state.id, token: token, interval: interval });
            if (showLoading && response.data.data.version !== Constants.VERSION) {
                toast.error("La versión de la aplicación ha sido actualizada, refresque la página por favor", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
            let lots = response.data.data.lots;
            let __lots = this.state.auction.lots;
            lots.map(item => {
                if (response.data.data.force_order) {
                    if (__lots[item.force_order]) {
                        if (__lots[item.force_order].manualOffer > item.manualOffer) {
                            item.manualOffer = __lots[item.force_order].manualOffer;
                        }
                    }
                    item.offerType = __lots[item.force_order]?.offerType || 1;
                    __lots[item.force_order] = item;
                }
                else {
                    let id_lot = item.id
                    if (item.id_lot.includes('-A')) {
                        id_lot = item.id_lot.replace('-A', '.1')
                    }
                    else if (item.id_lot.includes('-B')) {
                        id_lot = item.id_lot.replace('-B', '.2')
                    }
                    else if (item.id_lot.includes('-C')) {
                        id_lot = item.id_lot.replace('-C', '.3')
                    }
                    id_lot = parseFloat(id_lot).toFixed(2);
                    if (__lots[id_lot]) {
                        if (__lots[id_lot].manualOffer > item.manualOffer) {
                            item.manualOffer = __lots[id_lot].manualOffer;
                        }
                    }
                    item.offerType = __lots[id_lot]?.offerType || 1;
                    __lots[id_lot] = item;
                }
            });
            let fetchAuctionWithoutInterval = false;
            if (this.state.auction.force_order !== response.data.data.force_order && interval) {
                fetchAuctionWithoutInterval = true;
            }
            if (fetchAuctionWithoutInterval) {
                __lots = {};
            }
            await this.setState({
                auction: {
                    ...this.state.auction,
                    ...response.data.data,
                    dateTime: response.data.data.dateTime.date,
                    lots: __lots,
                    status: this.getEstado(response.data.data)
                },
                loading: false,
                timestamp: response.data.timestamp
            });
            if (this.state.auction.starting) {
                this.getCountDown();
            }
            if (this.state.auction.pending) {
                this.getCountDownStart();
            }
            if (showLoading && this.props.match.params.y !== undefined) {
                window.scrollTo(0, this.props.match.params.y);
                this.setState({
                    positionY: this.props.match.params.y
                })
                // elimino el param y de la url
                const { history, location } = this.props;
                const pathParts = location.pathname.split('/');
                // Eliminamos el último segmento de la ruta, que corresponde al parámetro "y"
                pathParts.pop();
                const newPath = pathParts.join('/');
                // Usamos history.replace para actualizar la URL sin recargar la página
                history.replace(newPath);
            }
            if (fetchAuctionWithoutInterval) {
                this.fetchAuction(false);
            }
        } catch (error) {
            await this.setState({ loading: false });
            if (error?.response?.status === 404) {
                const { data } = await getAllAuctionsService();
                if (data?.data?.inProgress && data?.data?.inProgress.length) {
                    let id = data.data.inProgress[0].id;
                    this.props.history.push(`/subastas/${id}`);
                }
                else {
                    this.props.history.push(`/`);
                }
            }
        }
    }

    getEstado = (auction) => {
        let dateFrom = auction.date_from;
        let dateTo = auction.date_to;
        let dateTime = auction.dateTime.date;
        if (auction.lots.length > 0) {
            let lots = [...auction.lots];
            lots.sort(function (a, b) {
                if (a.real_date_close > b.real_date_close) return 1;
                if (a.real_date_close < b.real_date_close) return -1;
                return 0;
            });
            dateTo = lots[lots.length - 1].real_date_close;
        }
        if (moment(auction.date_to) > moment(dateTime) && moment(dateFrom) < moment(dateTime)) {
            return 'Subasta en proceso';
        }
        if (moment(dateTo) > moment(dateTime) && moment(dateFrom) < moment(dateTime)) {
            return 'Cerrando subasta';
        }
        if (moment(dateTo) < moment(dateTime) && moment(dateFrom) < moment(dateTime)) {
            return 'Subasta finalizada';
        }
        return 'Subasta pendiente';
    }

    capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    handleChange = (event, item) => {
        const { name, value } = event.target;
        let valor = parseInt(Math.round(value / 10) * 10);
        if (valor < item.nextOffer) {
            item.manualOffer = item.nextOffer;
        }
        else {
            item.manualOffer = valor;
        }
    }

    offer = (item) => {
        let answer = false;
        if (item.nextOffer > 1000) {
            if (item.manualOffer >= item.nextOffer + item.nextOffer * 1.60) {
                answer = true;
            }
        }
        else {
            if (item.manualOffer >= item.nextOffer * 2) {
                answer = true;
            }
        }
        if (answer) {
            confirmAlert({
                title: `¿Esta seguro que desea confirmar la oferta?`,
                message: `Su oferta ${this.state.auction.moneySymbol} ${new Intl.NumberFormat("de-DE").format(item.manualOffer)}.`,
                buttons: [
                    {
                        label: 'Cancelar'
                    },
                    {
                        label: 'Confirmar',
                        onClick: () => {
                            this._offer(item)
                        }
                    }
                ]
            });
        }
        else if (window.use_confirm_offer) {
            confirmAlert({
                title: `Confirmar oferta`,
                message: `¿Esta seguro que desea confirmar la oferta?`,
                buttons: [
                    {
                        label: 'Cancelar'
                    },
                    {
                        label: 'Confirmar',
                        onClick: () => {
                            this._offer(item)
                        }
                    }
                ]
            });
        }
        else {
            this._offer(item)
        }
    }

    _offer = async (item) => {
        await this.setState({ loading: true });
        try {
            const automaticOffer = item.manualOffer > item.nextOffer ? item.manualOffer : null;
            let response = await addOffer({
                token: this.props.sessionProps.account.token,
                id_lot: item.id,
                offer: (!this.state.auction.allow_automatic_offers || item.offerType === 2) && item.manualOffer > item.nextOffer ? item.manualOffer : item.nextOffer,
                automaticOffer: this.state.auction.allow_automatic_offers && (!item.offerType || item.offerType === 1) ? automaticOffer : null,
            });
            let lots = this.state.auction.lots;
            let lot = response.data.data.lot;
            let id_lot = item.id
            if (item.id_lot.includes('-A')) {
                id_lot = item.id_lot.replace('-A', '.1')
            }
            else if (item.id_lot.includes('-B')) {
                id_lot = item.id_lot.replace('-B', '.2')
            }
            else if (item.id_lot.includes('-C')) {
                id_lot = item.id_lot.replace('-C', '.3')
            }
            if (this.state.auction.force_order) {
                id_lot = item.force_order;
            } else {
                id_lot = parseFloat(id_lot).toFixed(2);
            }
            lot.image_lot = lots[id_lot]?.image_lot;
            lot.image_remision = lots[id_lot]?.image_remision;
            lot.participation = lot.participations.filter(item => item === this.props.sessionProps?.account?.id).length > 0 ? true : null;
            lot.automaticOffer = lots[id_lot]?.automaticOffer || automaticOffer
            lot.offerType = lots[id_lot]?.offerType || 1;
            lots[id_lot] = lot;
            await this.setState({
                auction: {
                    ...this.state.auction,
                    lots: lots
                },
                loading: false
            });
            let msg = `Tu oferta ha sido ingresada con éxito`;
            if (response?.data?.message) {
                msg = response.data.message
            }
            toast(msg, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000,
            });
            if (this.props.configAccount.config?.use_notifications && lot.notify) {
                notifyUser({
                    token: this.props.sessionProps?.account?.token,
                    l: lot.id,
                    i: lot.notify,
                })
            }
        } catch (error) {
            await this.setState({ loading: false });
            if (error.response.status === 401) {
                this.props.logout();
                this.props.history.push(`/ingresar/${this.state.id}/${this.state.positionY}/subastas`);
            }
            else {
                toast.error("No fue posible ingresar tu oferta, reintentar nuevamente", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    getCountDown = async () => {
        if (this.state.intervalCountDown !== null) {
            clearInterval(this.state.intervalCountDown);
        }
        let interval = setInterval(async function () {
            await this.setState({
                timestamp: this.state.timestamp + 1
            })
        }.bind(this), 1000);
        await this.setState({
            intervalCountDown: interval
        });
    }

    _countDown = ({ item, isNormal }) => {
        if (this.state.timestamp === null || item.timestampClose === null || item.date_close === '2050-01-01 00:00:00') {
            return null;
        }
        //
        let difference = new Date(item.timestampClose * 1000).getTime() - new Date(this.state.timestamp * 1000).getTime();
        let seconds = Math.floor(difference / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);
        hours %= 24;
        minutes %= 60;
        seconds %= 60;
        if (!isNormal && days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
            if (item.status === Constants.STATUS_IN_PROGRESS) {
                item.timestampClose = null;
                item.countDownVisible = false;
                if (item.bestUserIdOffer !== null) {
                    item.status = Constants.STATUS_PENDING;
                    this.setItem(item);
                } else {
                    item.status = Constants.STATUS_NOT_AUCTIONED;
                    this.setItem(item);
                }
            }
            return null;
        }
        //
        if (!isNormal && days === 0 && hours === 0 && minutes === 0 && seconds <= 5 && seconds > 0) {
            if (item.message !== 'Cerrando lote...') {
                item.message = 'Cerrando lote...';
                this.setItem(item);
            }
            return (
                <React.Fragment>
                    <div className="timeclock">
                        <div>
                            Cerrando lote...
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        //
        let minutesToShow = 2;
        if (window.socket_io_customer === 'araujo_subastas' &&
            this.state.auction.auction_type === 5 &&
            !item.minutes_aditionals) {
            minutesToShow = 1;
        }
        if (!isNormal && days === 0 && hours === 0 && (minutes < minutesToShow || (minutes === minutesToShow && seconds === 0))) {
            if (item.countDownVisible !== true) {
                item.countDownVisible = true;
                this.setItem(item);
            }
            if (seconds.toString().length === 1) {
                seconds = '0' + seconds;
            }
            if (minutes.toString().length === 1) {
                minutes = '0' + minutes;
            }
            return (
                <React.Fragment>
                    <div className="timeclock">
                        <div>
                            {`00:${minutes}:${seconds}`}
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        //
        if (isNormal && (days > 0 || hours > 0 || minutes >= 2) && (item.status === Constants.STATUS_IN_PROGRESS || item.status === Constants.STATUS_PENDING)) {
            if (hours.toString().length === 1) {
                hours = '0' + hours;
            }
            if (minutes.toString().length === 1) {
                minutes = '0' + minutes;
            }
            if (seconds.toString().length === 1) {
                seconds = '0' + seconds;
            }
            return (
                <React.Fragment>
                    <div className="timeclock normal">
                        <div className='first-div'>
                            {
                                days > 0 ? (
                                    <div className='item'>
                                        <span>{days}</span>
                                        <span>Días</span>
                                    </div>
                                ) : null
                            }
                            <div className='item'>
                                <span>{hours}</span>
                                <span>Horas</span>
                            </div>
                            <div className='item'>
                                <span>{minutes}</span>
                                <span>Min</span>
                            </div>
                            <div className='item'>
                                <span>{seconds}</span>
                                <span>Seg</span>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        //
        return null;
    }

    countDown = ({ item, isNormal }) => {
        let countDown = this._countDown({ item, isNormal });
        if (countDown !== null) {
            return countDown;
        }
        return (<React.Fragment></React.Fragment>);
    }

    setItem = async (item) => {
        let lots = this.state.auction.lots;
        let id_lot = item.id
        if (item.id_lot.includes('-A')) {
            id_lot = item.id_lot.replace('-A', '.1')
        }
        else if (item.id_lot.includes('-B')) {
            id_lot = item.id_lot.replace('-B', '.2')
        }
        else if (item.id_lot.includes('-C')) {
            id_lot = item.id_lot.replace('-C', '.3')
        }
        id_lot = parseFloat(id_lot).toFixed(2);
        if (this.state.auction.force_order) {
            lots[item.force_order] = item;
        }
        else {
            lots[id_lot] = item;
        }
        await this.setState({
            auction: {
                ...this.state.auction,
                lots: lots
            }
        });
    }

    countDownCancelOffer = ({ item }) => {
        try {
            if (this._countDown({ item }) !== null || this.props.sessionProps.account === null || this.props.sessionProps.account.id !== item.bestUserIdOffer) {
                return (<React.Fragment></React.Fragment>);
            }
            if (item.automaticOffer >= item.nextOffer || this.state.timestamp === null || item.timestampCancelOffer === null) {
                return (<React.Fragment></React.Fragment>);
            }
            //
            let difference = new Date(item.timestampCancelOffer * 1000).getTime() - new Date(this.state.timestamp * 1000).getTime();
            let seconds = Math.floor(difference / 1000);
            let minutes = Math.floor(seconds / 60);
            let hours = Math.floor(minutes / 60);
            let days = Math.floor(hours / 24);
            hours %= 24;
            minutes %= 60;
            seconds %= 60;
            //
            if (days === -1 && hours === -1 && minutes === -1 && seconds + 30 <= 30 && seconds + 30 > 0) {
                return (
                    <div style={{ textAlign: 'center', marginTop: '0.5rem' }}>
                        <button onClick={() => this.deleteOffer(item)} style={{ background: 'transparent', border: 'none', color: 'red', textDecoration: 'underline' }}>
                            {`Cancelar oferta (${seconds + 30})`}
                        </button>
                    </div>
                );
            }
            //
            return (<React.Fragment></React.Fragment>);
        } catch (error) {
            return (<React.Fragment></React.Fragment>);
        }
    }

    getCountDownStart = async () => {
        if (this.state.intervalCountDownStart !== null) {
            clearInterval(this.state.intervalCountDownStart);
        }
        let interval = setInterval(function () {
            this.processCountDownStart();
        }.bind(this), 1000);
        await this.setState({
            intervalCountDownStart: interval
        });
    }

    processCountDownStart = () => {
        let auction = this.state.auction;
        var days = null; var hours = null; var minutes = null; var seconds = null;
        if (auction.timeLeft !== null) {
            let { day, hour, minute, second } = auction.timeLeft;
            days = parseInt(day);
            hours = parseInt(hour);
            minutes = parseInt(minute);
            seconds = parseInt(second);
            let pending = true;
            if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
                this.fetchAuction(false);
                pending = false;
                auction.timeLeft = null;
            }
            if (pending) {
                if (seconds === 0) {
                    minutes = minutes - 1;
                    seconds = 59;
                }
                else {
                    seconds = seconds - 1;
                }
                auction.timeLeft.second = seconds.toString().length === 1 ? '0' + seconds.toString() : seconds.toString();
                auction.timeLeft.minute = minutes.toString().length === 1 ? '0' + minutes.toString() : minutes.toString();
            }
            this.setState({
                auction: {
                    ...this.state.auction,
                    pending: pending,
                    timeLeft: auction.timeLeft
                }
            });
        }
    }

    componentWillUnmount = () => {
        this.state.intervalAuction !== null && clearInterval(this.state.intervalAuction);
        this.state.intervalCountDown !== null && clearInterval(this.state.intervalCountDown);
        this.state.intervalGetDateTime !== null && clearInterval(this.state.intervalGetDateTime);
        this.state.piesocket !== null && this.state.piesocket.close();
        console.log('closed')
    }

    deleteOffer = (item) => {
        confirmAlert({
            title: `Cancelar oferta`,
            message: `¿Esta seguro que desea cancelar la oferta? `,
            buttons: [
                {
                    label: 'Cancelar'
                },
                {
                    label: 'Confirmar',
                    onClick: () => {
                        this._deleteOffer(item)
                    }
                }
            ]
        });
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='col-12 text-center align-items-center'>
                        <XCircle color='red' size={60} fontWeight={'bold'} />
                        <b><h2 className='text-danger'>Cancelar oferta</h2></b>
                        <b><p style={{ fontSize: 16 }} className='mt-4 mb-4'>¿Esta seguro que desea cancelar la oferta?</p></b>
                        <div className='col-12 d-flex flex-column justify-content-center align-items-center'>
                            <button className='rounded-pill btn btn-danger col-6' onClick={onClose}>Cancelar</button>
                            <button style={{ fontSize: 18 }} className='rounded-pill btn btn-primary col-6 mt-2'
                                onClick={() => {
                                    this._deleteOffer(item)
                                    onClose();
                                }}
                            >
                                Aceptar
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    _deleteOffer = async (item) => {
        await this.setState({ loading: true })
        try {
            item.is_delete_offer = true;
            await this.setItem(item);
            let response = await deleteOffer({
                token: this.props.sessionProps.account.token,
                id: item.offerId,
                lot: item.id
            });
            await this.setState({ loading: false });
            toast(`La oferta ha sido cancelada con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    updateUrl = (url = null) => {
        this.props.history.push(window.location.pathname + '/' + this.state.positionY);
        if (url) {
            window.location.href = url;
        }
    }

    getIconFavorite = (item) => {
        if (![Constants.STATUS_PENDING, Constants.STATUS_DELIVERED, Constants.STATUS_NOT_AUCTIONED].includes(item.status)) {
            if (item && item.isFavorite) {
                return <div style={{
                    width: 'max-content',
                    background: 'transparent',
                    padding: '0.5rem 0.5rem',
                    position: 'absolute',
                    top: '0.5rem',
                    right: '1.5rem',
                    cursor: 'pointer',
                    zIndex: '4',
                }} onClick={() => this.addToFavorite(item)}>
                    <i className="fas fa-heart" style={{ fontSize: '24px', color: '#e91702' }}></i>
                </div>;
            }
            return <div style={{
                width: 'max-content',
                background: 'transparent',
                padding: '0.5rem 0.5rem',
                position: 'absolute',
                top: '0.5rem',
                right: '1.5rem',
                cursor: 'pointer',
                zIndex: '4',
            }} onClick={() => this.addToFavorite(item)}> <i className="far fa-heart" style={{ fontSize: '24px', color: '#e91702' }}></i>
            </div>;
        }
        return (<React.Fragment></React.Fragment>);
    }

    addToFavorite = async (item) => {
        this.setState({
            loading: true
        });
        try {
            await addToFavoriteService({
                token: this.props.sessionProps.account?.token,
                lotId: item.id,
                auctionId: item.id_remate,
            });
            let lots = this.state.auction.lots;
            let msg = 'Tu lote ha sido agregado a favoritos con éxito';
            if (item.isFavorite) {
                msg = 'Tu lote ha sido eliminado de favoritos con éxito';
            }

            const favoritesLotsId = []
            Object.entries(lots).forEach(([key, lot]) => {
                if (lot.id === item.id) {
                    item.isFavorite = !item.isFavorite;
                    lots[key] = item
                }
                if (lot.isFavorite) {
                    favoritesLotsId.push(lot.id)
                }
            });

            let tab = !favoritesLotsId.length ? 'all' : this.state.tab;
            this.setState({
                loading: false,
                auction: {
                    ...this.state.auction,
                    lots
                },
                favoritesLotsId,
                tab
            });

            toast(msg, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        } catch (error) {
            console.log(error)
            this.setState({
                loading: false
            });
            if (error.response?.status === 401) {
                this.props.logout();
                this.props.history.push(`/ingresar/${this.state.id}/${this.state.positionY}/subastas`);
            }
            else {
                let msg = 'No fue posible agregar tu lote a favoritos, reintentar nuevamente';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                    msg = error.response.data.message;
                }
                toast.error(msg, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    fetchFavorites = async () => {
        try {
            const token = this.props.sessionProps.account?.token;
            const result = await getFavorites({ token, auctionId: this.state.id });
            this.setState({
                favoritesLotsId: result?.data?.data?.lotsIds || []
            })
        } catch (error) {
            console.log(error)
        }

    }

    getIdLot = (id_lot) => {
        if (id_lot.includes('-')) {
            let part = id_lot.split("-");
            let number = parseInt(part[0], 10);
            return number + "-" + part[1];
        }
        return parseInt(id_lot);
    }

    card = ({ item, index }) => (
        <React.Fragment>
            <div id={`lot-${item.id}`} className={`card-lot-container ${this._countDown({ item }) !== null ? 'col-12 col-md-3 not-border-radius' : 'col-12 col-md-4'}`}>
                <div className='card-lot'>
                    {
                        this.countDown({ item })
                    }
                    <div className="card-lot-content">
                        <div className='p-0' style={{ margin: 'auto' }}>
                            {
                                item.extraImages ?
                                    <div className="single-collections-box">
                                        <Carousel variant="dark" interval={null}>
                                            {item.extraImages.map(image =>
                                                <Carousel.Item>
                                                    <img
                                                        onClick={() => this.updateUrl(`/lotes/${item.id}/${this.state.positionY}`)}
                                                        style={{ cursor: 'pointer' }}
                                                        className="d-block w-100"
                                                        src={`${image.image}`}
                                                        alt="Lote sin imagen"
                                                    />
                                                </Carousel.Item>
                                            )}
                                        </Carousel>
                                    </div>
                                    :
                                    <Link onClick={() => this.updateUrl()} to={`/lotes/${item.id}/${this.state.positionY}`}>
                                        <a>
                                            <div className="product-image">
                                                {
                                                    item.image_lot?.includes(Constants.BASE_URL_IMAGES) ?
                                                        <img src={`${item.image_lot}`} alt="Lote sin imagen" />
                                                        : <img src={`${Constants.BASE_URL}${item.image_lot}`} alt="Lote sin imagen" />
                                                }
                                            </div>
                                        </a>
                                    </Link>
                            }
                        </div>
                        {
                            this.getIconFavorite(item)
                        }

                        <div className="text-left p-3 w-100" style={{ background: 'white', borderRadius: '10px' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ width: '50%' }}>
                                    <h6 className='font-weight-bold mb-0' >Lote: {window.socket_io_customer === 'araujo_subastas' ? this.getIdLot(item.id_lot) : '#' + item.id_lot}</h6>
                                </div>
                                <div style={{ width: '50%' }}>
                                    {
                                        this.countDown({ item, isNormal: true })
                                    }
                                </div>
                            </div>
                            <p className='title-lot'>
                                <Link onClick={() => this.updateUrl()} to={`/lotes/${item.id}/${this.state.positionY}`}>
                                    {item.title}
                                </Link>
                            </p>
                            {
                                item.description === null || item.description === '' ?
                                    <p className="description-without">-</p>
                                    : <p className="description-with" dangerouslySetInnerHTML={{ __html: item.description }} />
                            }
                            {
                                item.date_close !== '2050-01-01 00:00:00' ? (
                                    <p className="date-close-and-price">
                                        <b>Cierre:</b>
                                        {` ${this.capitalize(moment(item.date_close).format('dddd D'))}, 
                                                  ${moment(item.date_close).format('HH:mm')} hs`}
                                    </p>
                                ) : null
                            }
                            <p className="quantity-lot">
                                <b>Cantidad:</b> {item.quantity}
                            </p>
                            <p className="date-close-and-price"><b>Base:</b>
                                {
                                    item.price_base > 0 ?
                                        ` ${this.state.auction.moneySymbol} ${new Intl.NumberFormat("de-DE").format(item.price_base)}` : ' Sin base'
                                }
                            </p>
                            {
                                this.props.configAccount?.config?.use_cost_packing && item.packing_cost > 0 ? (
                                    <p className="date-close-and-price"><b>Costo embalaje:</b>
                                        {` ${this.state.auction.moneySymbol} ${new Intl.NumberFormat("de-DE").format(item.packing_cost)}`}
                                    </p>
                                ) : null
                            }
                            {
                                item.status === Constants.STATUS_IN_PROGRESS && (item.message === undefined || item.message === null) ?
                                    <React.Fragment>
                                        <p className="actual-offer"><b>Oferta actual:{` ${this.state.auction.moneySymbol} ${new Intl.NumberFormat("de-DE").format(item.offer)}`}</b></p>
                                        {
                                            item.bestUserNameOffer &&
                                            <p className="best-offer-user"><b>Ganador actual:</b> {item.bestUserNameOffer}</p>
                                        }
                                        <div className="buy-checkbox-btn">
                                            <div className="item">
                                                {
                                                    this.props.sessionProps.account === null &&
                                                    <Link to={`/ingresar/${this.state.id}/${this.state.positionY}/subastas`}>
                                                        <button className={`btn btn-primary login-to-offer`}>
                                                            {
                                                                window.socket_io_customer === 'araujo_subastas' ?
                                                                    'OFERTAR' : 'INGRESAR PARA OFERTAR'
                                                            }
                                                        </button>
                                                    </Link>
                                                }
                                                {
                                                    this.props.sessionProps.account !== null && (this.props.sessionProps.account.id !== item.bestUserIdOffer || item.manualOffer > item.nextOffer) ?
                                                        item.participation ?
                                                            <Link to="#">
                                                                <button className={`btn btn-primary not_offered`}
                                                                    onClick={() => this.offer(item)}>
                                                                    OFERTAR {` ${this.state.auction.moneySymbol} ${new Intl.NumberFormat("de-DE").format(item.manualOffer)}`}
                                                                </button>
                                                            </Link>
                                                            :
                                                            <Link to="#">
                                                                <button className={`btn btn-primary not_offered`}
                                                                    onClick={() => this.offer(item)}>
                                                                    OFERTAR {` ${this.state.auction.moneySymbol} ${new Intl.NumberFormat("de-DE").format(item.manualOffer)}`}
                                                                </button>
                                                            </Link>
                                                        : null
                                                }
                                                {
                                                    this.props.sessionProps.account !== null && this.props.sessionProps.account.id === item.bestUserIdOffer && item.manualOffer <= item.nextOffer ?
                                                        <Link to="#">
                                                            <button className="btn btn-primary winning" disabled={true}>
                                                                <i className="fas fa-star"></i> MEJOR OFERTA
                                                            </button>
                                                        </Link> : null
                                                }
                                            </div>
                                            {
                                                !this.state.auction.allow_automatic_offers && this.props.sessionProps.account !== null && this.props.sessionProps.account.id !== item.bestUserIdOffer &&
                                                <div className="item text-center" style={{ marginTop: '5px' }}>
                                                    <div className="product-add-to-cart">
                                                        <div className="input-counter" style={{ width: '100%', maxWidth: '100%' }} >
                                                            <input type="number" min={item.nextOffer} step="10" placeholder="Ingresar monto manual" style={{ width: '100%', textAlign: 'center' }}
                                                                onChange={(event) => this.handleChange(event, item)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                this.state.auction.allow_automatic_offers && item.automaticOffer < item.nextOffer ? (
                                                    <div className="item text-center" style={{ marginTop: '5px' }}>
                                                        <div className="product-add-to-cart">
                                                            {
                                                                window.socket_io_customer === 'araujo_subastas' ? (
                                                                    <>
                                                                        <div className="input-counter d-flex" style={{ width: '100%', maxWidth: '100%' }} >
                                                                            <input type="number" min={item.nextOffer} step="10" placeholder="Ingresar oferta" style={{ width: '100%', textAlign: 'center' }}
                                                                                onChange={(event) => this.handleChange(event, item)} />
                                                                        </div>
                                                                        <div className="input-counter d-flex offer-type" style={{ width: '100%', maxWidth: '100%' }} >
                                                                            <button className={`no-border-right ${item.offerType === 1 ? 'active' : ''}`}
                                                                                onClick={() => item.offerType = 1}
                                                                            >
                                                                                Automática
                                                                            </button>
                                                                            <button className={`no-border-left ${item.offerType === 2 ? 'active' : ''}`}
                                                                                onClick={() => item.offerType = 2}
                                                                            >
                                                                                Manual
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <div className="input-counter" style={{ width: '100%', maxWidth: '100%' }} >
                                                                        <input type="number" min={item.nextOffer} step="10" placeholder="Ingresar oferta automática" style={{ width: '100%', textAlign: 'center' }}
                                                                            onChange={(event) => this.handleChange(event, item)} />
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                ) : this.state.auction.allow_automatic_offers && item.automaticOffer > 0 ? (
                                                    <div style={{
                                                        marginTop: '0.5rem',
                                                        textAlign: 'center',
                                                        fontSize: '14px',
                                                        fontWeight: 'bold'
                                                    }}>
                                                        <i className="fas fa-star"></i> Automática hasta {this.state.auction.moneySymbol}{item.automaticOffer}
                                                    </div>
                                                )
                                                    : null
                                            }
                                            {
                                                this.countDownCancelOffer({ item })
                                            }
                                        </div>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        {
                                            item.status === Constants.STATUS_PENDING || item.status === Constants.STATUS_DELIVERED ?
                                                <React.Fragment>
                                                    {
                                                        item.bestUserNameOffer &&
                                                        <p className="best-offer-user"><b>Ganador actual:</b> {item.bestUserNameOffer}</p>
                                                    }
                                                    {
                                                        this.props.sessionProps.account !== null && this.props.sessionProps.account.id === item.bestUserIdOffer ?
                                                            <Link to="#">
                                                                <button className="btn btn-primary winning" disabled={true}>
                                                                    <i className="fas fa-star"></i> SUBASTADO {this.state.auction.moneySymbol} {new Intl.NumberFormat("de-DE").format(item.offer)}
                                                                </button>
                                                            </Link>
                                                            :
                                                            <Link to="#" className="noHover">
                                                                <button disabled={true} className={`btn btn-primary auctioned`} >
                                                                    SUBASTADO {this.state.auction.moneySymbol} {new Intl.NumberFormat("de-DE").format(item.offer)}
                                                                </button>
                                                            </Link>
                                                    }
                                                </React.Fragment> : null
                                        }
                                        {
                                            item.status === Constants.STATUS_NOT_AUCTIONED &&
                                            <React.Fragment>
                                                {
                                                    item.bestUserNameOffer &&
                                                    <p className="best-offer-user"><b>Ganador actual:</b> {item.bestUserNameOffer}</p>
                                                }
                                                <Link to="#" className="noHover">
                                                    <button className="btn btn-primary not_auctioned" disabled={true}>
                                                        NO SUBASTADO
                                                    </button>
                                                </Link>
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );

    render() {
        return (
            <section className={`${this.props.sessionProps.account !== null ? 'pb-60' : 'ptb-60'}`} id="auction-detail-online">
                {this.state.loading && <Loading />}
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-12 col-md-9 text-center">
                            <div className="text-left d-flex flex-row mt-5">
                                <span className='rounded-pill mr-4 border-title-third text-primary-background' ></span>
                                <h1 className='font-weight-bold'><span className='text-black'>{this.state.auction.title}</span></h1>
                            </div>
                        </div>
                        {
                            this.state.auction.catalog !== '' && this.state.auction.catalog !== null ?
                                <div className="col-12 col-md-3 text-center d-flex catalog-icon" style={{ alignItems: 'end', justifyContent: 'end' }}>
                                    <a href={`${Constants.BASE_URL}${this.state.auction.catalog}`} target="_blank" rel="noopener noreferrer" style={{ width: 'auto' }}>
                                        <img src={downloadCatalog} style={{ cursor: 'pointer', width: '100%', maxWidth: '140px', marginBottom: '1rem' }} />
                                    </a>
                                </div> : null
                        }
                        {
                            this.state.auction.generateCatalogAutomatic && this.state.auction.catalogUrlAutomatic !== null ?
                                <div className="col-12 col-md-3 text-center d-flex catalog-icon" style={{ alignItems: 'end', justifyContent: 'end' }}>
                                    <a href={this.state.auction.catalogUrlAutomatic} target="_blank" rel="noopener noreferrer" style={{ width: 'auto' }}>
                                        <img src={downloadCatalog} style={{ cursor: 'pointer', width: '100%', maxWidth: '140px', marginBottom: '1rem' }} />
                                    </a>
                                </div> : null
                        }
                    </div>

                    <div className="box-subastas">
                        <div className="row box-top-subastas">
                            <div className='col-12 col-md-9 d-flex align-items-center desktop'>
                                <img src={calendar} alt="calendar" className="" style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', width: '50px', height: '50px', marginRight: '1rem' }} />
                                <div style={{ marginLeft: '1rem' }}>
                                    <label>INICIO</label>
                                    <br />
                                    <label style={{ marginBottom: '0px' }}>CIERRE</label>
                                </div>
                                <div style={{ marginLeft: '1rem' }}>
                                    <label style={{ color: '#323232', fontWeight: 'bold' }}>
                                        {
                                            !this.state.loading && `
                                                ${this.capitalize(moment(this.state.auction.date_from).format('dddd D'))} de 
                                                ${moment(this.state.auction.date_from).format('MMMM')}, 
                                                ${moment(this.state.auction.date_from).format('HH:mm')} hs`
                                        }
                                    </label>
                                    <br />
                                    <label style={{ color: '#323232', fontWeight: 'bold', marginBottom: '0px' }}>
                                        {
                                            !this.state.loading && ` 
                                                ${this.capitalize(moment(this.state.auction.date_to).format('dddd D'))} de 
                                                ${moment(this.state.auction.date_to).format('MMMM')}, 
                                                ${moment(this.state.auction.date_to).format('HH:mm')} hs`
                                        }
                                    </label>
                                </div>
                            </div>
                            <div className="col-12 col-md-9 mobile date-from-to">
                                <img src={calendar} alt="calendar" className=""
                                    style={{ margin: 'auto', alignItems: 'center', justifyContent: 'center', display: 'flex', width: '50px', height: '50px' }} />
                                <label style={{ marginBottom: '0rem', marginTop: '1rem' }}>INICIO</label>
                                <label style={{ color: '#323232', fontWeight: 'bold' }}>
                                    {
                                        !this.state.loading && `
                                                ${this.capitalize(moment(this.state.auction.date_from).format('dddd D'))} de 
                                                ${moment(this.state.auction.date_from).format('MMMM')}, 
                                                ${moment(this.state.auction.date_from).format('HH:mm')} hs`
                                    }
                                </label>
                                <br />
                                <label style={{ marginBottom: '0rem' }}>CIERRE</label>
                                <label style={{ color: '#323232', fontWeight: 'bold' }}>
                                    {
                                        !this.state.loading && ` 
                                                ${this.capitalize(moment(this.state.auction.date_to).format('dddd D'))} de 
                                                ${moment(this.state.auction.date_to).format('MMMM')}, 
                                                ${moment(this.state.auction.date_to).format('HH:mm')} hs`
                                    }
                                </label>
                            </div>
                            <div className='col-12 col-md-3 text-center justify-content-center d-flex align-items-center flex-column'>
                                <div className='text-black font-weight-bold mb-2' >Estado de la subasta</div>
                                {
                                    this.state.auction?.status === 'Subasta en proceso' ?
                                        <div className='p-0 pb-1 pt-1 w-50 text-white btn-success rounded-pill text-center'>En proceso</div>
                                        :
                                        this.state.auction?.status === 'Subasta finalizada' ?
                                            <div className='p-0 pb-1 pt-1 w-50 text-white btn-danger rounded-pill text-center'>Finalizada</div>
                                            :
                                            this.state.auction?.status === 'Cerrando subasta' ?
                                                <div className='p-0 pb-1 pt-1 w-50 text-white btn-warning rounded-pill text-center'>Cerrando</div>
                                                :
                                                null
                                }
                            </div>
                        </div>
                    </div>

                    {
                        this.props.sessionProps.account !== null &&
                        <div className="row" style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
                            <div className="col-12 text-left">
                                <span onClick={() => this.props.history.push(`/mi-cuenta/1/${this.state.id}/subastas`)} className="item-myaccount"> <i className="fas fa-user"></i> Mi cuenta</span>
                                <span onClick={() => this.props.history.push(`/mi-cuenta/2/${this.state.id}/subastas`)} className="item-myaccount"><i className="fas fa-gavel"></i>  Mis ofertas</span>
                                <span onClick={() => this.props.history.push(`/mi-cuenta/3/${this.state.id}/subastas`)} className="item-myaccount"><i className="fas fa-shopping-basket"></i>  Mis Compras</span>
                            </div>
                        </div>
                    }

                    <div className='row mt-5'>
                        <div className="col-12"
                            dangerouslySetInnerHTML={{ __html: this.state.auction.description }} />
                    </div>

                    {
                        !Object.values(this.state.auction.lots).length || this.state.auction.keep_image ? (
                            <>
                                <div className='w-100 desktop'>
                                    <img src={`${Constants.BASE_URL}${this.state.auction.image}`} alt="" className='w-100' />
                                </div>
                                <div className='w-100 mobile'>
                                    <img
                                        src={`${Constants.BASE_URL}${this.state.auction.imageMobile ? this.state.auction.imageMobile : this.state.auction.image}`}
                                        alt="" className='w-100' />
                                </div>

                            </>
                        ) : null
                    }

                    {
                        this.state.auction.youtube_link !== null && this.state.auction.youtube_link !== '' ? (
                            <div className="container video-youtube" style={{ padding: 0, marginTop: '2rem' }}>
                                <ReactPlayer controls={true} url={this.state.auction.youtube_link} width="100%" height="100%" />
                            </div>
                        ) : null
                    }

                    {
                        this.state.favoritesLotsId.length > 0 ?
                            <div className="row mt-5 tabs-selection">
                                <div className="col-12 text-right">
                                    <button className={`${this.state.tab === 'all' ? 'active' : ''}`}
                                        onClick={() => {
                                            this.setState({
                                                tab: 'all'
                                            })
                                        }}>Todos</button>
                                    <span>|</span>
                                    <button
                                        className={`${this.state.tab === 'favorites' ? 'active' : ''}`}
                                        onClick={() => {
                                            this.setState({
                                                tab: 'favorites'
                                            })
                                        }}>Favoritos</button>
                                </div>
                            </div>
                            : null
                    }

                    <ActiveNotifications />

                    <div style={{ marginTop: '2rem' }}>
                        {
                            this.state.tab === 'favorites' ? (
                                !this.state.favoritesLotsId.length ? (
                                    <div className="row pl-2 pr-2">
                                        Aún no agregaste ningun lote a favoritos
                                    </div>
                                ) : (
                                    <>
                                        <div className="row pl-2 pr-2">
                                            {
                                                Object.values(this.state.auction.lots).filter(lot => this.state.favoritesLotsId.includes(lot.id)).map((item, index) =>
                                                    this._countDown({ item }) !== null &&
                                                    <this.card item={item} index={index} />
                                                )
                                            }
                                        </div>
                                        <div className="row pl-2 pr-2">
                                            {
                                                Object.values(this.state.auction.lots).filter(lot => this.state.favoritesLotsId.includes(lot.id)).map((item, index) =>
                                                    (item.status === Constants.STATUS_IN_PROGRESS || item.status === Constants.STATUS_INITIAL) && this._countDown({ item }) === null &&
                                                    <this.card item={item} index={index} />
                                                )
                                            }
                                        </div>
                                        <div className="row pl-2 pr-2">
                                            {
                                                Object.values(this.state.auction.lots).filter(lot => this.state.favoritesLotsId.includes(lot.id)).map((item, index) =>
                                                    item.status !== Constants.STATUS_IN_PROGRESS && item.status !== Constants.STATUS_INITIAL && this._countDown({ item }) === null &&
                                                    <this.card item={item} index={index} />

                                                )
                                            }
                                        </div>
                                    </>
                                )
                            ) : (
                                <>
                                    <div className="row pl-2 pr-2">
                                        {
                                            console.log(this.state.auction.lots)
                                        }
                                        {
                                            Object.values(this.state.auction.lots).map((item, index) =>
                                                this._countDown({ item }) !== null &&
                                                <this.card item={item} index={index} />
                                            )
                                        }
                                    </div>
                                    <div className="row pl-2 pr-2">
                                        {
                                            Object.values(this.state.auction.lots).map((item, index) =>
                                                (item.status === Constants.STATUS_IN_PROGRESS || item.status === Constants.STATUS_INITIAL) && this._countDown({ item }) === null &&
                                                <this.card item={item} index={index} />
                                            )
                                        }
                                    </div>
                                    <div className="row pl-2 pr-2">
                                        {
                                            Object.values(this.state.auction.lots).map((item, index) =>
                                                item.status !== Constants.STATUS_IN_PROGRESS && item.status !== Constants.STATUS_INITIAL && this._countDown({ item }) === null &&
                                                <this.card item={item} index={index} />

                                            )
                                        }
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </section >
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
    configAccount: state.accountReducer
})

const mapDispatchToProps = (dispatch) => ({
    logout: () => {
        dispatch(logoutAction());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(AuctionDetail);